.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.button:hover,
.button:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  .button {
    padding: 8px 18px 12px;
  }
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #2E333C;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 700;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 20px;
  font-family: 'catamaran', sans-serif;
  line-height: 1.5;
}
.unit caption {
  display: none;
}
.flag {
  background: #2E333C;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 70px;
  position: relative;
}
@media (max-width: 1023px) {
  .unit {
    margin-top: 45px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-top: 35px;
  }
}
@media (max-width: 1023px) {
  .unit {
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-bottom: 35px;
  }
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
@media (max-width: 1023px) {
  .part {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .part {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-bottom: 10px;
  }
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 7px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #333333;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
  background-color: #000;
}
#slides {
  float: left;
  width: 100%;
  height: 100vh !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  opacity: 0.45;
}
.cb-layout2 #slides,
.cb-layout4 #slides {
  height: 80vh !important;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #2E333C;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #2E333C;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'catamaran', sans-serif;
  line-height: 1.42857143;
  color: #333333;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #2E333C;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #2E333C;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  #disp .foot input,
  #disp .foot a {
    padding: 8px 18px 12px;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  display: none;
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'catamaran', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #eaebec;
  min-height: 60px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 30px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 15px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'catamaran', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  background: #eaebec;
  height: 60px;
  border: 1px solid #333333;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-bottom: 10px;
  }
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    padding: 8px 18px 12px;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #333333;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 10px;
  }
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  .two-step-verification-container a {
    padding: 8px 18px 12px;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 700;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 700;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.60756193vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
body.cb-toggle-target-active {
  overflow: hidden;
}
.homelink {
  float: right;
  height: 120px;
  margin-top: 30px;
  transition: all 0.2s;
}
@media (max-width: 1023px) {
  .homelink {
    height: 72px;
    margin-top: 14px;
  }
}
.logo {
  width: auto;
  height: 100%;
}
.logo.logo--white {
  display: none;
}
.cb-layout5 .logo,
.cb-layout6 .logo {
  display: block;
}
.cb-layout5 .logo.logo--white,
.cb-layout6 .logo.logo--white {
  display: none;
}
#head {
  float: left;
  width: 100%;
  padding-bottom: 60px;
  position: relative;
}
#head:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 13px;
  width: 0;
  height: 2px;
  background-color: #fff;
  transform-origin: 0 0;
  transform: translateX(-50%);
  transition: all 0.2s, transform 0.3s 0.3s;
  transform-origin: 50% 50%;
}
.show-content2 #head:after {
  width: 350px;
  transform: translateX(-50%) rotate(-4deg);
}
.section--title #head {
  margin-top: 30px;
}
.section--title #head:after {
  background-color: #333333;
}
.cb-layout6 .section--title #head {
  margin-top: 180px;
}
@media (max-width: 1023px) {
  .cb-layout6 .section--title #head {
    margin-top: 120px;
  }
}
@media (max-width: 767px) {
  .cb-layout6 .section--title #head {
    margin-top: 90px;
  }
}
@media (max-width: 767px) {
  #head {
    padding-bottom: 34px;
  }
  #head:after {
    bottom: 0;
  }
  .show-content #head:after {
    width: 210px;
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'catamaran', sans-serif;
  font-size: 20px;
  line-height: 1.5;
  color: #333333;
  overflow: hidden;
  hyphens: none;
  box-sizing: border-box;
}
.cb-layout3 .wrapper {
  padding-top: 150px;
}
@media (max-width: 767px) {
  .cb-layout3 .wrapper {
    padding-top: 86px;
  }
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 86vw;
  width: 850px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--two .desk,
.section--three .desk,
.section--four .desk {
  width: 1196px;
}
.section--footer .desk,
.section--legal .desk,
.section--multimood .desk,
.section--title .desk {
  width: 1024px;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  transition: all 0.4s;
}
@media (max-width: 1023px) {
  .section--header.cb-scroll-triggered--active {
    background-color: #fff;
    box-shadow: 0 0 3px rgba(120, 120, 120, 0.6);
  }
}
.section--mediumblue {
  background-color: #4D515F;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}
.section--mediumblue .sectionBG {
  background-color: #4D515F;
}
.section--mediumblue .triangle--top {
  border-bottom-color: #4D515F;
}
.section--mediumblue .triangle--bottom {
  border-top-color: #4D515F;
}
.section--lightgray {
  background-color: #E4E5E7;
  -webkit-font-smoothing: antialiased;
}
.section--lightgray .sectionBG {
  background-color: #E4E5E7;
}
.section--lightgray .triangle--top {
  border-bottom-color: #E4E5E7;
}
.section--lightgray .triangle--bottom {
  border-top-color: #E4E5E7;
}
.section--darkblue {
  background-color: #2E333C;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}
.section--darkblue a {
  text-decoration: none;
}
.section--triangle {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent !important;
}
.section--triangle.section--base {
  display: none;
  margin-top: 90px;
  margin-bottom: 90px;
}
@media (max-width: 1023px) {
  .section--triangle.section--base {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--triangle.section--base {
    margin-top: 45px;
  }
}
@media (max-width: 1023px) {
  .section--triangle.section--base {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .section--triangle.section--base {
    margin-bottom: 45px;
  }
}
.cb-layout2 .section--triangle.section--base,
.cb-layout3 .section--triangle.section--base {
  display: flex;
}
#view.base--empty.cb-layout2 .section--triangle.section--base,
#view.base--empty.cb-layout3 .section--triangle.section--base {
  display: none;
}
.section--triangle.section--farnorth {
  display: none;
  margin-top: 90px;
  margin-bottom: 90px;
}
@media (max-width: 1023px) {
  .section--triangle.section--farnorth {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--triangle.section--farnorth {
    margin-top: 45px;
  }
}
@media (max-width: 1023px) {
  .section--triangle.section--farnorth {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .section--triangle.section--farnorth {
    margin-bottom: 45px;
  }
}
.cb-layout4 .section--triangle.section--farnorth {
  display: flex;
}
#view.farnorth--empty.cb-layout4 .section--triangle.section--farnorth {
  display: none;
}
.section--request {
  margin-top: 90px;
}
@media (max-width: 1023px) {
  .section--request {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .section--request {
    margin-top: 45px;
  }
}
.section--footer a:hover,
.section--legal a:hover,
.section--footer a:focus,
.section--legal a:focus {
  color: #fff;
  opacity: 0.6;
}
.section--legal {
  border-top: 1px solid rgba(255, 255, 255, 0.16);
}
.headercontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 112px;
}
.moodcontent {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  width: 100%;
  color: #fff;
  text-align: center;
}
#edit.cb-layout1.c2 .moodcontent,
#edit.cb-layout2.c2 .moodcontent,
#edit.cb-layout4.c2 .moodcontent {
  z-index: initial;
  position: relative;
  top: 0;
  transform: translateY(0%);
  background-color: #000;
  padding: 20px 0 120px;
}
.moodvideo {
  float: left;
  width: 100%;
  position: relative;
  background-color: #000;
  z-index: 4;
  margin: 10vh 0;
}
#edit .moodvideo:after {
  content: 'Mood Video Area';
  font-size: 60%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: absolute;
  color: #71caf3;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
#view.cb-layout2 .moodvideo,
#view.cb-layout4 .moodvideo {
  height: 80vh !important;
}
#view .moodvideo {
  margin: 0;
  z-index: 1;
  height: 100vh;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
#view .moodvideo .area,
#view .moodvideo .part,
#view .moodvideo .unit,
#view .moodvideo .body {
  height: 100%;
  margin: 0;
}
#view .moodvideo video {
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.45;
}
#view .moodvideo .desk {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
video {
  background: #000 no-repeat center center;
  background-size: cover;
}
.downlink {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  z-index: 4;
  align-items: center;
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  display: none;
}
.downlink:hover,
.downlink:focus {
  color: #3D9FD6;
}
.cb-layout1 .downlink {
  display: flex;
}
.downlink__icon {
  float: left;
  margin: 0 10px;
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  border: 1px solid #fff;
  background-size: 12px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-white.svg);
}
.downlink:hover .downlink__icon {
  border-color: #3D9FD6;
  background-image: url(/images/arrow-down-blue.svg);
}
.downlink__label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.downlink__label.downlink__label--left {
  right: 100%;
}
.downlink__label.downlink__label--right {
  left: 100%;
}
.moodTriangle {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: #fff;
  border-width: 0 0 8.05555556vw 100vw;
  display: none;
}
.cb-layout2 .moodTriangle,
.cb-layout4 .moodTriangle {
  display: block;
}
.sectionBG {
  float: left;
  width: 100%;
}
.backlink {
  float: left;
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  border: 1px solid #000;
  background-color: #FFF;
  background-size: 16px 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-black.svg);
  cursor: pointer;
  display: none;
}
.backlink:hover,
.backlink:focus {
  background-color: #4D515F;
  background-image: url(/images/arrow-left-white.svg);
}
.cb-layout6 .backlink {
  display: block;
}
.cb-layout2.cb-dynamic-page--service .backlink {
  display: block;
}
.triangle {
  float: left;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
}
.triangle--top {
  border-width: 0 0 8.05555556vw 100vw;
}
.triangle--bottom {
  border-width: 8.05555556vw 100vw 0 0;
}
.request {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 90px;
  margin-bottom: 180px;
}
@media (max-width: 1023px) {
  .request {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .request {
    margin-top: 45px;
  }
}
@media (max-width: 1023px) {
  .request {
    margin-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .request {
    margin-bottom: 90px;
  }
}
.request__title {
  float: left;
  width: 100%;
  font-size: 30px;
  line-height: 1.33333333;
  margin-bottom: 50px;
  font-weight: 300;
}
.footcontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  line-height: 1.46666667;
  margin: 30px 0;
}
@media (max-width: 1023px) {
  .footcontent {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .footcontent {
    margin: 20px 0;
  }
}
.footpart {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 0;
}
@media (max-width: 1023px) {
  .footpart {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .footpart {
    width: 100%;
    margin: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.16);
    padding-top: 40px;
  }
  .footpart:first-child {
    border: none;
    padding-top: 0;
  }
}
#services {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#services .div {
  float: left;
  margin-top: 10px;
}
#services .div:first-child {
  margin-top: 0;
}
.footnav {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footnav div.sub1 {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footnav div.sub1 > .item {
  margin-top: 10px;
}
.footnav div.sub1 > .item.init {
  margin-top: 0;
}
.foothomelink {
  float: left;
  margin-top: 10px;
}
.vcard {
  float: left;
}
.vcard .org {
  font-weight: 700;
}
#quicklinks {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#quicklinks .meta {
  float: left;
  margin-top: 14px;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: url(/images/quicklink.svg);
}
#quicklinks .meta:first-child {
  margin-top: 0;
}
.swisscircle {
  float: left;
  margin-top: 36px;
}
#social {
  float: left;
}
#social .meta {
  float: left;
  width: 46px;
  height: 46px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  border: 1px solid #fff;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-facebook.svg);
  margin-right: 16px;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta.service_instagram {
  background-image: url(/images/social-instagram.svg);
}
#social .meta.service_twitter {
  background-image: url(/images/social-twitter.svg);
}
#partner {
  float: left;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}
#partner .meta {
  float: left;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  margin: 8px 0;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
#partner .meta.service_digitalwinterthur {
  width: 170px;
  height: 22px;
  background-image: url(/images/digitalwinterthur.png);
}
#partner .meta.service_swisscircle {
  width: 100px;
  height: 40px;
  background-image: url(/images/swisscircle.png);
}
#partner .meta.service_swissbau {
  width: 58px;
  height: 58px;
  background-image: url(/images/swissbau.png);
}
.credits {
  float: left;
  margin: 21px 0;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.46666667;
}
.credits .sep {
  float: left;
  margin: 0 5px 0 8px;
}
@media (max-width: 767px) {
  .credits {
    flex-direction: column;
    align-items: flex-start;
  }
  .credits .sep {
    display: none;
  }
}
#legal {
  float: left;
}
#legal .meta {
  display: inline-block;
  margin: 0 3px;
}
@media (max-width: 767px) {
  #legal {
    margin-top: 4px;
  }
}
.filterNavi {
  float: left;
  position: relative;
  width: 220px;
  box-sizing: border-box;
  display: none;
}
.cb-layout5 .filterNavi {
  display: block;
}
.filterNavi__content {
  position: absolute;
  left: 0px;
  top: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0s 0.2s;
}
.filterNavi.cb-toggle-target-active .filterNavi__content {
  border-color: #000;
  transition: all 0s;
  background-color: #FFF;
}
.filterNavi__item {
  float: left;
  width: 100%;
  font-size: 16px;
  line-height: 1.625;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #000;
  padding: 7px 16px 11px;
  box-sizing: border-box;
  text-decoration: none;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  cursor: pointer;
  white-space: nowrap;
  margin-top: -44px;
  opacity: 0;
  transition: all 0.2s ease-out, background 0s;
}
.filterNavi__item:last-child {
  border-bottom: 1px solid #000;
}
.filterNavi__item:hover,
.filterNavi__item:focus,
.filterNavi__item.is-active {
  color: #fff;
  background-color: #4D515F;
}
.filterNavi__item.is-active {
  font-weight: 700;
}
.filterNavi.cb-toggle-target-active .filterNavi__item {
  margin-top: 0;
  opacity: 1;
}
.filterNavi__item--toggle {
  background-color: #FFF;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
  opacity: 1;
  position: relative;
  border: 1px solid #000;
  padding-right: 44px;
}
.filterNavi__item--toggle:after {
  content: '';
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  border-width: 5px 5px 0 5px;
  transition: all 0.2s;
}
.filterNavi.cb-toggle-target-active .filterNavi__item--toggle:after {
  transform: translateY(-50%) rotate(-180deg);
}
#cb-cookie-warning {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1990;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none !important;
}
.cb-cookie-warning--container {
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  background-color: #000;
  color: #FFF;
  font-family: 'catamaran', sans-serif;
  font-size: 16px;
  line-height: 1.375;
  transform: translateY(200%);
  transition: all 0.5s 0.5s cubic-bezier(0.05, 0.8, 0.5, 1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 13px 46px;
}
@media (max-width: 1023px) {
  .cb-cookie-warning--container {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.show-content .cb-cookie-warning--container {
  transform: translateY(0);
}
.cb-cookie-warning--text {
  float: left;
}
.cb-cookie-warning--text .cookie--link {
  display: inline-block;
}
@media (max-width: 767px) {
  .cb-cookie-warning--text {
    text-align: left;
  }
}
.cb-cookie-warning--actions {
  float: left;
  display: flex;
}
@media (max-width: 1023px) {
  .cb-cookie-warning--actions {
    margin-top: 20px;
  }
}
.cb-cookie-warning__button {
  float: left;
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-top: 10px;
  color: #FFF;
  border-color: #FFF;
  margin-left: 10px;
}
.cb-cookie-warning__button:hover,
.cb-cookie-warning__button:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  .cb-cookie-warning__button {
    padding: 8px 18px 12px;
  }
}
.cb-cookie-warning__button:first-child {
  margin-left: 0;
}
@media (max-width: 1023px) {
  .cb-cookie-warning__button {
    padding: 6px 10px;
  }
}
a {
  color: inherit;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #3D9FD6;
}
h1 {
  font-size: 70px;
  line-height: 1;
  font-weight: 100;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  position: relative;
  text-align: center;
  hyphens: manual;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.3s 0.4s;
}
.section--title h1 {
  font-size: 60px;
  line-height: 1.2;
  text-transform: none;
  font-weight: 300;
}
.show-content2 h1 {
  opacity: 1;
  transform: translateY(0);
}
h2 {
  font-size: 48px;
  line-height: 1.20833333;
  font-weight: 300;
  hyphens: auto;
}
h3 {
  font-size: 36px;
  line-height: 1.27777778;
  font-weight: 400;
}
h4 {
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
}
h5 {
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
}
h5 a.open {
  padding: 5px;
  box-sizing: border-box;
  display: inline-block;
}
.loud {
  font-size: 28px;
  line-height: 1.5;
  letter-spacing: 0.02em;
}
.section--one .area {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.cb-layout1 .section--one .area.main {
  margin-top: 90px;
}
@media (max-width: 1023px) {
  .cb-layout1 .section--one .area.main {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .cb-layout1 .section--one .area.main {
    margin-top: 45px;
  }
}
.section--two .area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section--three .area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.section--four .area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.moodcontent .area {
  margin-bottom: -20px;
  opacity: 0;
  transform: translateY(-30px);
  transition: all 0.4s 0.7s;
}
@media (max-width: 1023px) {
  .moodcontent .area {
    margin-bottom: -15px;
  }
}
@media (max-width: 767px) {
  .moodcontent .area {
    margin-bottom: -10px;
  }
}
#edit .moodcontent .area {
  min-height: 50px;
}
.show-content2 .moodcontent .area {
  opacity: 1;
  transform: translateY(0);
}
.section--one .area .unit .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
@media (min-width: 768px) {
  .section--one .area .unit .part.link.tiny {
    width: auto;
    min-width: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.section--one .area .seam {
  width: 1024px;
  max-width: 86vw;
}
.section--one .area .flat {
  width: 100vw;
  max-width: 86vw;
}
.section--one .area .flat .body {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.section--one .area .flat .pict {
  width: 100vw;
}
.section--one .area .flat .pict .cb-image-container {
  position: relative;
  padding-bottom: 56.2%;
}
.section--one .area .flat .pict * {
  height: 100%;
}
.section--one .area .flat .pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.section--one .area .flat .pict img {
  position: absolute;
  left: 0;
  right: 0;
}
.section--one .area .flat .pict:first-child {
  margin-top: 20px !important;
}
@media (max-width: 1023px) {
  .section--one .area .flat .pict:first-child {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .section--one .area .flat .pict:first-child {
    margin-top: 10px;
  }
}
.section--one .area .flat .pict:last-child {
  margin-bottom: 20px !important;
}
@media (max-width: 1023px) {
  .section--one .area .flat .pict:last-child {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .section--one .area .flat .pict:last-child {
    margin-bottom: 10px;
  }
}
.section--two .area .pure .pict.tall:first-child .cb-image-container,
.section--two .area .seam .pict.tall:first-child .cb-image-container {
  height: 0;
  padding-bottom: 66.66666667%;
  position: relative;
}
.section--two .area .pure .pict.tall:first-child .cb-image-container img,
.section--two .area .seam .pict.tall:first-child .cb-image-container img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.section--two .area .pure .film.tall:first-child,
.section--two .area .seam .film.tall:first-child {
  height: 383px;
}
@media (max-width: 1391px) {
  .section--two .area .pure .film.tall:first-child,
  .section--two .area .seam .film.tall:first-child {
    height: 27.53414809vw;
  }
}
@media (max-width: 1023px) {
  .section--two .area .pure .film.tall:first-child,
  .section--two .area .seam .film.tall:first-child {
    height: 27.34375vw;
  }
}
@media (max-width: 767px) {
  .section--two .area .pure .film.tall:first-child,
  .section--two .area .seam .film.tall:first-child {
    height: 57.33333333vw;
  }
}
.section--two .area .pure .film.tall:first-child video,
.section--two .area .seam .film.tall:first-child video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section--two .area .pure .pict.tall:first-child .cb-image-container:after {
  content: '';
  position: absolute;
  left: -1px;
  bottom: -1px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: #fff;
  border-width: 0 0 48px 577px;
}
@media (max-width: 1391px) {
  .section--two .area .pure .pict.tall:first-child .cb-image-container:after {
    border-width: 0 0 3.45075485vw 41.48094896vw;
  }
}
@media (max-width: 1023px) {
  .section--two .area .pure .pict.tall:first-child .cb-image-container:after {
    border-width: 0 0 3.38541667vw 41.40625vw;
  }
}
@media (max-width: 767px) {
  .section--two .area .pure .pict.tall:first-child .cb-image-container:after {
    border-width: 0 0 6.93333333vw 86.4vw;
  }
}
.section--two .area .pure .film.tall:first-child:after {
  content: '';
  position: absolute;
  left: -1px;
  bottom: -1px;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: #fff;
  border-width: 0 0 48px 577px;
}
@media (max-width: 1391px) {
  .section--two .area .pure .film.tall:first-child:after {
    border-width: 0 0 3.45075485vw 41.48094896vw;
  }
}
@media (max-width: 1023px) {
  .section--two .area .pure .film.tall:first-child:after {
    border-width: 0 0 3.38541667vw 41.40625vw;
  }
}
@media (max-width: 767px) {
  .section--two .area .pure .film.tall:first-child:after {
    border-width: 0 0 6.93333333vw 86.4vw;
  }
}
.section--two .area .flat {
  text-align: center;
  margin-bottom: -20px;
}
@media (max-width: 1023px) {
  .section--two .area .flat {
    margin-bottom: -15px;
  }
}
@media (max-width: 767px) {
  .section--two .area .flat {
    margin-bottom: -10px;
  }
}
.section--two .area .flat:last-child {
  margin-bottom: 70px;
}
@media (max-width: 1023px) {
  .section--two .area .flat:last-child {
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  .section--two .area .flat:last-child {
    margin-bottom: 35px;
  }
}
.section--three .area .unit {
  margin-top: 18px;
  margin-bottom: 18px;
}
.section--three .area .unit .part {
  margin-top: 5px;
  margin-bottom: 5px;
}
.section--three .area .seam {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section--three .area .seam .body {
  width: 850px;
  max-width: 100%;
}
.section--four .area .unit {
  margin-top: 15px;
  margin-bottom: 15px;
}
.section--four .area .unit .part.pict .cb-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section--four .area .unit .part.pict img {
  filter: grayscale(100%);
}
.moodcontent .area .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.moodcontent .area .unit .cb-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.emaillink a.open,
div.link a.open {
  display: inline-block;
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
div.emaillink a.open:hover,
div.link a.open:hover,
div.emaillink a.open:focus,
div.link a.open:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  div.emaillink a.open,
  div.link a.open {
    padding: 8px 18px 12px;
  }
}
.part--icon {
  position: relative;
}
.part--icon:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 1000000px;
  background-color: rgba(255, 255, 255, 0.4);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: all 0.4s;
  pointer-events: none;
}
.part--icon:hover:after,
.part--icon:focus:after {
  transform: translateY(-50%) translateX(-50%) scale(1.5);
}
@media (max-width: 767px) {
  .part--icon:after {
    width: 60px;
    height: 60px;
  }
  .part--icon:hover:after,
  .part--icon:focus:after {
    transform: translateY(-50%) translateX(-50%) scale(1.2);
  }
}
.part--icon.part--icon3D:after {
  background-image: url(/images/icon-teaser-3D.svg);
}
.part--icon.part--icon360:after {
  background-image: url(/images/icon-teaser-360.svg);
}
.part--icon.part--iconMotion:after {
  background-image: url(/images/icon-teaser-motion.svg);
}
.part--icon.part--iconVideo:after {
  background-image: url(/images/icon-teaser-video.svg);
}
.section--backlink .part.link {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cb-layout6 .section--backlink .part.link {
  display: flex;
}
.togglenavigation {
  position: fixed;
  top: 52px;
  left: 46px;
  z-index: 2003;
  width: 46px;
  height: 46px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: all 0.4s;
}
.togglenavigation.cb-scroll-triggered--active,
.cb-layout3 .togglenavigation {
  border-color: #2E333C;
  background-color: #fff;
}
body.cb-toggle-target-active .togglenavigation {
  border-color: #fff !important;
  background-color: transparent;
}
.cb-layout5 .togglenavigation,
.cb-layout6 .togglenavigation {
  background-color: #fff;
  border-color: #000 !important;
}
@media (max-width: 1023px) {
  .togglenavigation {
    left: 24px;
    top: 20px;
  }
}
.tline {
  position: absolute;
  top: 12px;
  left: 10px;
  width: 24px;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), opacity 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-scroll-triggered--active .tline,
.cb-layout3 .tline {
  background-color: #2E333C;
}
body.cb-toggle-target-active .tline {
  background-color: #fff !important;
}
.cb-layout5 .tline,
.cb-layout6 .tline {
  background-color: #000 !important;
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 12px;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
}
.navioverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2001;
  background-color: #000;
  opacity: 0;
  width: 0;
  transition: opacity 0.8s, width 0s 0.8s;
}
body.cb-toggle-target-active .navioverlay {
  opacity: 0.6;
  width: 100%;
  transition: opacity 0.8s;
}
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2002;
  width: 450px;
  max-width: 100%;
  height: 100%;
  overflow-y: hidden;
  transform: translateX(-100%);
  transition: all 0.8s;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.navigation::-webkit-scrollbar {
  display: none;
}
body.cb-toggle-target-active .navigation {
  transform: translateX(0);
}
.naviBG {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
}
.naviBG__rectangle {
  float: left;
  width: calc(100% - 194px);
  height: 100%;
  background-color: #2E333C;
}
.naviBG__triangle {
  float: left;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent;
  border-left-color: #2E333C;
  border-width: 0 0 100vh 0;
  transition: all 0.8s;
}
body.cb-toggle-target-active .naviBG__triangle {
  border-width: 0 0 100vh 194px;
}
.navicontent {
  float: left;
  width: 100%;
  position: relative;
  z-index: 3;
  padding-left: 46px;
  box-sizing: border-box;
  margin: 200px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0;
  transition: all 0.2s;
}
body.cb-toggle-target-active .navicontent {
  transition: all 0.4s 0.4s;
  opacity: 1;
}
@media (max-width: 767px) {
  .navicontent {
    padding-left: 24px;
    margin: 120px 0 60px;
  }
}
.navihome {
  float: left;
  margin-top: 24px;
  color: #82858F;
  font-size: 38px;
  line-height: 1;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: 300;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  position: relative;
  padding-bottom: 8px;
}
.navihome:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #82858F;
  transition: all 0.4s;
}
.navihome:hover,
.navihome:focus {
  color: #82858F;
}
.navihome:hover:after,
.navihome:focus:after {
  width: 100%;
}
@media (max-width: 767px) {
  .navihome {
    font-size: 34px;
  }
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
.navicontent div.sub1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: -100000px;
}
.navicontent div.sub1 > .item {
  margin-top: 24px;
}
.navicontent div.sub1 > .item.init {
  margin-top: 0;
}
.navicontent div.sub1 > .item > .menu {
  color: #fff;
  font-size: 38px;
  line-height: 1;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: 300;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  position: relative;
  padding-bottom: 8px;
}
@media (max-width: 767px) {
  .navicontent div.sub1 > .item > .menu {
    font-size: 34px;
  }
}
.navicontent div.sub1 > .item > .menu:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: all 0.4s;
}
.navicontent div.sub1 > .item > .menu:hover:after,
.navicontent div.sub1 > .item > .menu:focus:after,
.navicontent div.sub1 > .item > .menu.path:after {
  width: 100%;
}
.part.auth form {
  float: left;
  width: 100%;
  text-align: left;
}
.part.auth .body,
.part.auth .foot {
  width: 100%;
  margin: 0;
}
.part.auth fieldset {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1023px) {
  .part.auth fieldset {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .part.auth fieldset {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .part.auth fieldset {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .part.auth fieldset {
    margin-bottom: 10px;
  }
}
.part.auth fieldset:first-child {
  margin-top: 0;
}
.part.auth label.name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .part.auth label.name {
    width: 30%;
    min-height: 60px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.part.auth input.text,
.part.auth input.password {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #eaebec;
  min-height: 60px;
  padding: 5px 20px 7px;
}
.part.auth input.text.fail,
.part.auth input.password.fail {
  border: 2px solid #ae2121;
}
.part.auth input.text.fade,
.part.auth input.password.fade {
  opacity: 0.3;
}
@media (min-width: 768px) {
  .part.auth input.text,
  .part.auth input.password {
    float: right;
    width: 66%;
  }
}
.auth-login-button {
  float: right;
  margin-top: 20px;
}
@media (max-width: 1023px) {
  .auth-login-button {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .auth-login-button {
    margin-top: 10px;
  }
}
.part.auth input.submit {
  float: left;
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.part.auth input.submit:hover,
.part.auth input.submit:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  .part.auth input.submit {
    padding: 8px 18px 12px;
  }
}
@media (max-width: 767px) {
  .cb-layout5 .homelink {
    display: none;
  }
  .headercontent {
    padding-left: 94px;
    justify-content: space-between;
  }
  .cb-layout5 .headercontent {
    justify-content: flex-end;
  }
  .headerlinks {
    margin: 20px 24px 24px 0;
  }
}
@media (max-width: 767px) {
  .wrapper {
    font-size: 18px;
    line-height: 1.44444444;
  }
  h1 {
    font-size: 36px;
  }
  .section--title h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 14px;
  }
  .loud {
    font-size: 20px;
  }
}
.section--three .area {
  margin-top: 22px;
  margin-bottom: 22px;
}
.moodcontent .area {
  margin-top: 20px;
}
.section--one .area .flat .pict {
  margin-top: 35px;
  margin-bottom: 35px;
}
.section--one .area .flat .pict + .pict {
  margin-top: -33px;
}
.section--four .area {
  width: 100%;
}
.section--four .area > .unit {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.section--four .area .cb-album .body,
.section--four .area .head,
.section--four .area .foot,
.section--four .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim {
  width: 40%;
}
.section--four .area > .slim .head,
.section--four .area > .slim .foot,
.section--four .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim .tiny {
  width: 100%;
}
.section--four .area {
  width: 110%;
  margin-left: -5%;
}
.dr-slider {
  float: left;
  width: 100%;
  margin-top: 90px;
  margin-bottom: 90px;
}
@media (max-width: 1023px) {
  .dr-slider {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .dr-slider {
    margin-top: 45px;
  }
}
@media (max-width: 1023px) {
  .dr-slider {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .dr-slider {
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  .dr-slider {
    margin-top: 180px;
    margin-bottom: 180px;
  }
}
@media (max-width: 767px) and (max-width: 1023px) {
  .dr-slider {
    margin-top: 120px;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .dr-slider {
    margin-top: 90px;
  }
}
@media (max-width: 767px) and (max-width: 1023px) {
  .dr-slider {
    margin-bottom: 120px;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .dr-slider {
    margin-bottom: 90px;
  }
}
.dr-slider__section {
  float: left;
  width: 100%;
  margin-top: 46px;
  position: relative;
}
.dr-slider__section:first-child {
  margin-top: 0;
}
@media (max-width: 767px) {
  .dr-slider__section {
    margin-top: 30px;
  }
}
.dr-slider__section--area {
  z-index: 2;
}
#edit .dr-slider__section--info {
  display: none;
}
.dr-slider__title {
  float: left;
  width: 100%;
  text-align: center;
}
.dr-slider__control {
  position: absolute;
  top: 100%;
  margin-top: 46px;
  width: 46px;
  height: 46px;
  border: 1px solid #333333;
  box-sizing: border-box;
  background-size: 16px 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.dr-slider__control:hover,
.dr-slider__control:focus {
  border-color: #3D9FD6;
}
#edit .dr-slider__control {
  display: none;
}
@media (max-width: 1023px) {
  .dr-slider__control {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .dr-slider__control {
    top: initial;
    margin-top: 0;
    bottom: 100%;
    margin-bottom: 30px;
  }
}
.dr-slider__control--prev {
  left: 46px;
  background-image: url(/images/arrow-left-gray.svg);
}
.dr-slider__control--prev:hover,
.dr-slider__control--prev:focus {
  background-image: url(/images/arrow-left-blue.svg);
}
@media (max-width: 1023px) {
  .dr-slider__control--prev {
    left: 20px;
  }
}
.dr-slider__control--next {
  right: 46px;
  background-image: url(/images/arrow-right-gray.svg);
}
.dr-slider__control--next:hover,
.dr-slider__control--next:focus {
  background-image: url(/images/arrow-right-blue.svg);
}
@media (max-width: 1023px) {
  .dr-slider__control--next {
    right: 20px;
  }
}
.dr-slider .area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: calc(100% + 20px);
  margin-left: -10px;
}
#edit .dr-slider .area {
  box-sizing: border-box;
  padding: 0 80px;
}
#view .dr-slider .area {
  flex-wrap: nowrap;
  align-items: flex-start;
}
.dr-slider .area .unit {
  margin-left: 10px;
  margin-right: 10px;
  width: 23.91752577%;
}
@media (max-width: 1200px) {
  .dr-slider .area .unit {
    width: 48.36065574%;
  }
}
.dr-slider .area .unit .part {
  margin-top: 15px;
  margin-bottom: 15px;
}
#view .dr-slider .dr-slider__section--area .area .unit {
  margin-top: 0;
  margin-bottom: 0;
  flex-shrink: 0;
}
#view .dr-slider .dr-slider__section--area .area .unit .part {
  margin-top: 0;
  margin-bottom: 0;
  display: none;
}
#view .dr-slider .dr-slider__section--area .area .unit .part.pict:first-child {
  display: block;
}
.sliderInfo {
  float: left;
  width: 100%;
  text-align: center;
}
.sliderInfo .part {
  margin-top: 15px;
  margin-bottom: 15px;
}
.sliderInfo .part:first-child {
  margin-top: 0;
}
.sliderInfo .part:last-child {
  margin-bottom: 0;
}
.dr-teasers {
  float: left;
  width: 100%;
}
.cb-layout3 .dr-teasers {
  margin-top: 90px;
  margin-bottom: 90px;
}
@media (max-width: 1023px) {
  .cb-layout3 .dr-teasers {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .cb-layout3 .dr-teasers {
    margin-top: 45px;
  }
}
@media (max-width: 1023px) {
  .cb-layout3 .dr-teasers {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .cb-layout3 .dr-teasers {
    margin-bottom: 45px;
  }
}
.dr-teasers__section {
  float: left;
  width: 100%;
  margin-top: 90px;
}
@media (max-width: 1023px) {
  .dr-teasers__section {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .dr-teasers__section {
    margin-top: 45px;
  }
}
.dr-teasers__section:first-child {
  margin-top: 0;
}
.dr-teasers__title {
  float: left;
  width: 100%;
  text-align: center;
}
.dr-teasers__actions {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dr-teasers__button {
  float: left;
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-right: 10px;
}
.dr-teasers__button:hover,
.dr-teasers__button:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  .dr-teasers__button {
    padding: 8px 18px 12px;
  }
}
.dr-teasers__button:last-child {
  margin-right: 0;
}
.dr-teasers_gallery {
  float: left;
  width: calc(100% + 2px);
  margin: -1px;
}
.dr-teaser {
  float: left;
  margin: 1px;
  width: calc(50% - 2px);
  position: relative;
  color: #fff;
}
.dr-teaser:hover,
.dr-teaser:focus {
  color: #fff;
}
@media (max-width: 1023px) {
  .dr-teaser {
    width: calc(100% - 2px);
  }
}
.dr-teaser--slim {
  width: calc(25% - 2px);
}
@media (max-width: 1023px) {
  .dr-teaser--slim {
    width: calc(50% - 2px);
  }
}
@media (max-width: 767px) {
  .dr-teaser--slim {
    width: calc(100% - 2px);
  }
}
.dr-teaser__image {
  float: left;
  width: 100%;
  height: 0;
  padding-bottom: 57.97705944%;
  position: relative;
  background-color: #000;
  overflow: hidden;
}
.dr-teaser--portrait .dr-teaser__image {
  padding-bottom: 116.19644723%;
}
.dr-teaser__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.4s;
}
.dr-teaser:hover .dr-teaser__image img {
  opacity: 0.5;
  transform: scale(1.05);
}
.dr-teaser__info {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  padding: 0 14px 14px 54px;
}
.dr-teaser__info:after {
  content: '';
  position: absolute;
  left: 14px;
  bottom: 14px;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  box-sizing: border-box;
  background-size: 4px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-i-white.svg);
}
.dr-teaser__title {
  float: left;
  width: 100%;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 1.46666667;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding-bottom: 6px;
}
@media (min-width: 1024px) {
  .dr-teaser__title {
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.2s, transform 0s 0.2s;
  }
  .dr-teaser:hover .dr-teaser__title {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.4s;
  }
}
.dr-teaser__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 80px;
  height: 80px;
  border-radius: 1000000px;
  background-color: rgba(255, 255, 255, 0.4);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: all 0.4s;
}
.dr-teaser:hover .dr-teaser__icon {
  transform: translateY(-50%) translateX(-50%) scale(1.5);
}
@media (max-width: 767px) {
  .dr-teaser__icon {
    width: 60px;
    height: 60px;
  }
  .dr-teaser:hover .dr-teaser__icon {
    transform: translateY(-50%) translateX(-50%) scale(1.2);
  }
}
.dr-teaser--icon3D .dr-teaser__icon {
  background-image: url(/images/icon-teaser-3D.svg);
}
.dr-teaser--icon360 .dr-teaser__icon {
  background-image: url(/images/icon-teaser-360.svg);
}
.dr-teaser--iconMotion .dr-teaser__icon {
  background-image: url(/images/icon-teaser-motion.svg);
}
.dr-teaser--iconVideo .dr-teaser__icon {
  background-image: url(/images/icon-teaser-video.svg);
}
/*# sourceMappingURL=./screen-small.css.map */